import * as React from "react"
import { graphql } from 'gatsby'
import SbEditable from 'storyblok-react'
 
import Layout from "../components/layout"
import DynamicComponent from "../components/dynamicComponent"
import useStoryblok from "../lib/storyblok"
 
const IndexPage = ({ data, location }) => { 
  let story = data.storyblokEntry
  story = useStoryblok(story, location)
 
  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })
 
  return (
    <Layout pageTitle="Home Page">
      <SbEditable content={ story.content }>
          <h1>{ story.content.title }</h1>
          { components }
      </SbEditable>
    </Layout>
  )}
 
export default IndexPage

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: {eq: "home"}) {
      content
      name
    }
  }
`